import { Component, OnInit } from '@angular/core';
import { NavController, PopoverController } from '@ionic/angular';
import { AlertService } from '../alert.service';
import { GlobalService } from '../global.service';
import { LoadingService } from '../loading.service';
import { SubirService } from '../subir.service';

@Component({
  selector: 'app-newepc',
  templateUrl: './newepc.page.html',
  styleUrls: ['./newepc.page.scss'],
})
export class NewepcPage implements OnInit {
  Nit;
  Nombre;
  Telefono;
  Correo;
  Tipocontra;
  constructor(public alert: AlertService, public subir: SubirService, public popoverController: PopoverController, public global: GlobalService, public loading: LoadingService, public nav: NavController) { }

  ngOnInit() {
  }

  Cerrar() {
    this.global.Popover.dismiss(null, 'Cancelado');
  }

  Subir() {
    const data = {
      option: 'crearepc',
      nit: this.Nit,
      nombrecli: this.Nombre,
      telefono: this.Telefono,
      correo: this.Correo,
      tcontrato: this.Tipocontra
    };
    this.global.consultar( data, (err, response) => {
      console.log( response);
          if(response){
            this.alert.AlertOneButton('Información', 'EPC nuevo cargado correctamente', 'Ok', ()=> {
              this.global.Popover.dismiss(null, 'Cargado');
            });
          }else if (response === null || response === undefined) {
            this.alert.AlertOneButton('Error', 'No se logro cargar el epc, Intente Nuevamente', 'Ok', ()=> {
              this.global.Popover.dismiss(null, 'Cancelado');
            });
          }
    });

  }
}
