import { Component, OnInit } from '@angular/core';
import { NavController, PopoverController } from '@ionic/angular';
import { AlertService } from '../alert.service';
import { GlobalService } from '../global.service';
import { LoadingService } from '../loading.service';
import { SubirService } from '../subir.service';

@Component({
  selector: 'app-newinver',
  templateUrl: './newinver.page.html',
  styleUrls: ['./newinver.page.scss'],
})
export class NewinverPage implements OnInit {
  Tipopersona;
  Tipodocumento;
  Nombre;
  Telefono;
  Correo;
  constructor(public alert: AlertService, public subir: SubirService, public popoverController: PopoverController, public global: GlobalService, public loading: LoadingService, public nav: NavController) { }

  ngOnInit() {
  }

  Cerrar() {
    this.global.Popover.dismiss(null, 'Cancelado');
  }

  Subir() {
    const data = {
      option: 'crearinversionista',
      tipopersona: this.Tipopersona,
      tipodocumento: this.Tipodocumento,
      nombre: this.Nombre,
      telefono: this.Telefono,
      correo: this.Correo
    };
    this.global.consultar( data, (err, response) => {
      console.log( response);
          if(response){
            this.alert.AlertOneButton('Información', 'Inversionista nuevo cargado correctamente', 'Ok', ()=> {
              this.global.Popover.dismiss(null, 'Cargado');
            });
          }else if (response === null || response === undefined) {
            this.alert.AlertOneButton('Error', 'No se logro cargar el Inversionista, Intente Nuevamente', 'Ok', ()=> {
              this.global.Popover.dismiss(null, 'Cancelado');
            });
          }
    });

  }
}
