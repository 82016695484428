import { Component, OnInit } from '@angular/core';
import { NavController, PopoverController } from '@ionic/angular';
import { AlertService } from '../alert.service';
import { GlobalService } from '../global.service';
import { LoadingService } from '../loading.service';
import { SubirService } from '../subir.service';

@Component({
  selector: 'app-factgenerada',
  templateUrl: './factgenerada.page.html',
  styleUrls: ['./factgenerada.page.scss'],
})
export class FactgeneradaPage implements OnInit {

  constructor(public alert: AlertService, public subir: SubirService, public popoverController: PopoverController,public global: GlobalService, public loading: LoadingService, public nav: NavController) { }

  ngOnInit() {
  }


  Cerrar() {
    this.global.Popover.dismiss(null, 'Cancelado');
  }
}
